<template>
  <div>
    <div class="integratedCooperationBox">
      <div class="integratedCooperation clearfix">
        <dl>
          <dt>豸信CA集成合作伙伴</dt>
          <dd>豸信CA提供合法专业的网络身份认证与管理服务，能够为合作伙伴提供合法、安全、高效的网络信赖产品和服务</dd>
        </dl>
        <img src="@/assets/image/cooperation/banner.png" alt="合作伙伴">
        <div class="integrationServicesApply">
          <div class="integrationServices">
            <h3>云证书API集成服务(实名+证书申请 )</h3>
            <el-button type="primary" @click="pageJump('cloudCertificate',1,1)">业务申请</el-button>
          </div>
          <div class="integrationServices">
            <h3>云证书API集成服务(证书申请 )</h3>
            <el-button type="primary" @click="pageJump('cloudCertificate',1,0)">业务申请</el-button>
          </div>
          <div class="integrationServices">
            <h3>网络身份认证集成服务</h3>
            <el-button type="primary" @click="pageJump('identityAuth')">业务申请</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperationApplication">
      <h2>合作申请</h2>
      <img src="@/assets/image/cooperation/step.png" alt="合作申请">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    pageJump (name, type, specs) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.$store.commit('user/setOrderNo', '')
        this.pageJump(name, type, specs)
      })
      this.$store.commit('user/setOrderNo', '')
      this.$store.commit('user/seAppId', '')
      this.$router.push({ name: name, query: { type: type, specs: specs } })
    },
  }
}
</script>

<style lang="scss" scoped>
.newHeadWarp {
  width: 100%;
  height: 60px;
  padding: 0 48px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
  h3 {
    font-size: 14px;
    color: #303b50;
    line-height: 60px;
    font-weight: normal;
    float: left;
  }
}
.logo {
  margin-right: 20px;
  color: #1c67f3;
  font-size: 44px;
  line-height: 60px;
  cursor: pointer;
  float: left;
}
.integratedCooperationBox {
  background: url('~@/assets/image/cooperation/background.png') left top
    no-repeat;
  background-size: 100% 100%;
}
.integratedCooperation {
  width: 1300px;
  height: 564px;
  margin: 0 auto;
  position: relative;
  img {
    width: 408px;
    position: absolute;
    top: 121px;
    right: 101px;
  }
  dl {
    padding-top: 206px;
  }
  dt {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    font-weight: 700;
  }
  dd {
    width: 605px;
    margin-top: 35px;
    font-size: 21px;
    color: #303b50;
    line-height: 37px;
  }
}
.integrationServicesApply {
  display: flex;
  position: absolute;
  bottom: -60px;
  left: 0;
}
.integrationServices {
  width: 392px;
  height: 128px;
  background: linear-gradient(180deg, #eff2f9 0%, #f8fafe 100%);
  box-shadow: 0px 4px 16px 0px rgba(156, 201, 252, 0.36);
  border-radius: 2px;
  border: 2px solid #ffffff;
  padding: 40px 28px 42px 34px;
  display: flex;
  justify-content: space-between;
  margin-right: 48px;
  h3 {
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
    margin-right: 8px;
  }
  .el-button {
    font-size: 16px;
  }
}
.cooperationApplication {
  width: 1300px;
  margin: 175px auto 0;
  padding-bottom: 138px;
  h2 {
    font-size: 40px;
    color: #303b50;
    line-height: 53px;
    text-align: center;
    margin-bottom: 100px;
  }
  img {
    width: 1170px;
    margin: 0 auto;
  }
}
</style>